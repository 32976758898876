


jQuery(".gstar-ajax-loadmore").on('click', function (event) {

    var that = jQuery(this);
    console.log('clicked');

    event.preventDefault();
    var post_type = that.attr('data-post-type');
    var page = parseInt(that.attr('data-page'));
    var per_page = parseInt(that.attr('data-per-page'));
    var max_page = parseInt(that.attr('data-max-page'));
    var offset = parseInt(that.attr('data-offset'));
    var tax = that.attr('data-tax');
    var term = that.attr('data-term');


    if (!that.hasClass('active')) {
        that.addClass('active');
        jQuery('.gstar-community-index-block').addClass('active');

        if (page >= max_page) {
            that.addClass("hidden");
        }
    } else {

        page++;

        jQuery.ajax({
            url: ajaxURL,
            type: 'post',
            data: {
                action: 'gstar_ajax_pagination',
                post_type: post_type,
                page: page,
                per_page: per_page,
                offset: offset,
                tax: tax,
                term: term,
            },
            success: function (html) {
                jQuery(html).appendTo(jQuery('.gstar-community-index'));
                that.attr('data-page', page);
                if (page >= max_page) {
                    that.addClass("s-hidden");
                }
            }
        });
    }
});
